import axios from 'axios';
import { BASE_API_URL } from '../domain/constants/api';
import { UserPrincipal } from '../domain/userPrincipal.model';
async function getUserPrincipalByUserId(jwt:string){
      
    var config = {
        method: 'get',
        // url: BASE_API_URL + '/external/user-principal/' + userId,
        url: BASE_API_URL + '/external/user-principal/byJwtSub' ,
        // url: BASE_API_URL + '/external/user-principal/' + 'userId001',
        headers: { 
            'Authorization': 'Bearer '+ jwt
            
            // ,
            // 'Content-Type': 'application/json'
        }
    };

    return axios(config)
    .then(function (response) {
      console.log(JSON.stringify(response.data));
    })
    .catch(function (error) {
      console.log(error);
      throw new Error(error.response.data);
    });
}

async function postUserPrincipal(jwt:string, body:UserPrincipal){
    return axios({
        method: 'post',
        url: BASE_API_URL + '/external/user-principal',
        headers: {
            'Authorization': 'Bearer '+ jwt,
            'Content-Type': 'application/json'
        },
        data : JSON.stringify(body)
        
    })
    
}

export { getUserPrincipalByUserId , postUserPrincipal };