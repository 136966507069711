import React from 'react';
import Drawer from '@mui/material/Drawer';
import { Container } from '@mui/material';
import { LoginForm } from './LoginForm';

export const LoginPage: React.FunctionComponent = () => {
    return <Container>
      <Drawer variant="permanent" ></Drawer>
      <LoginForm />
    </Container>
}