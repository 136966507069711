import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './view/App';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import store from './application/store/store';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { environment } from './domain/constants/environments';
const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
  );


root.render(
  // <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_OAUTH_CLIENT_ID}>\
  <GoogleOAuthProvider clientId={environment.googleOauthClientId}>
      <React.StrictMode>
        <Provider store={store}>
          <App />
        </Provider>
      </React.StrictMode>
  </GoogleOAuthProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
