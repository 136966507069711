import axios from 'axios';
import { BASE_API_URL } from '../../domain/constants/api';
import { LoginResponse } from '../../domain/login.model';


function loginWithCredentials(username: string, password: string, clientId: string) {
  return axios
    .post<LoginResponse>(`${BASE_API_URL}/auth/login`, {
      username,
      password,
      clientId
    }, {
      withCredentials: true
    })
    .then(res => res.data);
}

function fetchLoginSession() {
  return axios
    .post<LoginResponse>(`${BASE_API_URL}/auth/token`, {}, {
      withCredentials: true
    })
    .then(res => res.data);
}

export { loginWithCredentials, fetchLoginSession };
