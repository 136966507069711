import { createSlice } from '@reduxjs/toolkit';
const userProfileSlice = createSlice({
  name: 'userProfile',
  initialState: {
    profile: localStorage.getItem('profile') || null,
    userJwt: localStorage.getItem('userJwt') || null,
    isRegistered: localStorage.getItem('isRegistered') || null
  },
  reducers: {
    setProfile: (state, action) => {
      state.profile = action.payload;
    },
    setUserJwt: (state, action) => {
      state.userJwt = action.payload;
    },
    setIsRegistered: (state, action) => {
      state.isRegistered = action.payload;
    }
  },
});

export const { setProfile , setUserJwt , setIsRegistered} = userProfileSlice.actions;

export default userProfileSlice.reducer;