import React, { FormEvent, useState } from 'react';
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { GoogleLogin } from '@react-oauth/google';
import { useDispatch, useSelector } from 'react-redux';
import { getJwt, validateGoogleIdToken } from '../../application/getJwt';
import { setIsRegistered, setProfile, setUserJwt } from '../../application/features/userProfile.slice';
import { getUserPrincipalByUserId } from '../../application/userPrincipal';
import { UserLoginState, loginByCredentials } from '../../application/features/userLogin.slice';
import { RootState } from '../../application/store/root.reducer';
import Alert from '@mui/material/Alert';
import { getUrlQueryParam, urlQueryParamKey } from '@qcases/common-ui';

export function LoginForm() {

    const [username, setUsername] = useState("")
    const [password, setPassword] = useState("")
    const [usernameError, setUsernameError] = useState(false)
    const [passwordError, setPasswordError] = useState(false)

    const userLoginState = useSelector<RootState, UserLoginState>((state: RootState) => state.userLogin);
    const dispatch = useDispatch();
    //TODO - get and store birthday,gender,location
    const googleLogin = async (response: any) => {
        try {
            const info = await validateGoogleIdToken(response.credential);
            const qCaseJwt = await getJwt(response.credential, 'google');
            localStorage.setItem('profile', JSON.stringify(info.data));
            dispatch(setProfile(localStorage.getItem('profile')));
            localStorage.setItem('userJwt', qCaseJwt.data.jwt);
            dispatch(setUserJwt(localStorage.getItem('userJwt')));

            await getUserPrincipalByUserId(qCaseJwt.data.jwt)
                .then(
                    (response) => {
                        localStorage.setItem('isRegistered', 'true');
                        dispatch(setIsRegistered(localStorage.getItem('isRegistered')));
                    }

                )
        } catch (e: any) {
            console.log(e);
            if (e.message === 'This user does not exist') {
                localStorage.setItem('isRegistered', 'false');
                dispatch(setIsRegistered(localStorage.getItem('isRegistered')));
            } else {
                console.log('Error on login.');
            }
        }


    };
    const handleSubmitCredentials = (event: FormEvent<any>) => {
        event.preventDefault();
        setUsernameError(false)
        setPasswordError(false)
        if (username === '') {
            setUsernameError(true)
        }
        if (password === '') {
            setPasswordError(true)
        }
        if (!event.currentTarget.checkValidity()) {
            return;
        }
        const data = new FormData(event.currentTarget);
        dispatch(loginByCredentials({
            username: data.get("username")?.toString() || '',
            password: data.get("password")?.toString() || '',
            clientId: getUrlQueryParam(urlQueryParamKey.CLIENT_ID)
        }));
    };

    return (
        <Container component="main" maxWidth="xs">
            <Box
                sx={{
                    boxShadow: 3,
                    borderRadius: 2,
                    px: 4,
                    py: 6,
                    marginTop: 8,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                }}
            >
                <Typography component="h1" variant="h5">
                    Sign in
                </Typography>
                <Box component="form" onSubmit={handleSubmitCredentials} noValidate sx={{ mt: 1 }}>
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        id="username"
                        label="Username or Email Address"
                        name="username"
                        autoComplete="username"
                        autoFocus
                        error={usernameError}
                        onChange={(e) => { setUsername(e.target.value) }}
                    />
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        name="password"
                        label="Password"
                        type="password"
                        id="password"
                        autoComplete="current-password"
                        error={passwordError}
                        onChange={(e) => { setPassword(e.target.value) }}
                    />
                    {userLoginState.error ?
                        <Alert severity="error">Login failed, please input correct username and password</Alert>
                        : null}
                    <FormControlLabel
                        control={<Checkbox value="remember" color="primary" />}
                        label="Remember me"
                    />
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3, mb: 2 }}
                    >
                        Sign In
                    </Button>
                    <Grid sx={{
                        pb: 3
                    }}
                        container>
                        <Grid item xs>
                            <Link href="#" variant="body2">
                                Forgot password?
                            </Link>
                        </Grid>
                        <Grid item>
                            <Link href="#" variant="body2">
                                {"Don't have an account? Sign Up"}
                            </Link>
                        </Grid>
                    </Grid>
                    <GoogleLogin
                        onSuccess={
                            (credentialResponse) => googleLogin(credentialResponse)
                        }
                        onError={() => {
                            console.log('Login Failed');
                        }}
                    />
                </Box>
            </Box>
        </Container>
    );
}