import { applyMiddleware, configureStore } from '@reduxjs/toolkit'
import createSagaMiddleware from 'redux-saga';
import { authRootSaga } from './root.saga';
import { rootReducer } from './root.reducer';

const sagaMiddleware = createSagaMiddleware();

applyMiddleware();

export default configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(sagaMiddleware),
});
sagaMiddleware.run(authRootSaga);

