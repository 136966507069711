import React, { useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { postUserPrincipal } from '../../application/userPrincipal';
import { Roles, UserPrincipal } from '../../domain/userPrincipal.model';
import { setIsRegistered } from '../../application/features/userProfile.slice';
import Container from '@mui/material/Container';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { Dayjs } from 'dayjs';
import { Button, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import countryList from 'react-select-country-list';
import Input from '@mui/material/Input';

interface RegistrationPageProps {
}
type RegisteringUserCredentials = {
  username: string;
  email: string;
  password: string;
  confirmedPassword: string;
}
const RegistrationPage: React.FC<RegistrationPageProps> = (props) => {

  const userProfile = useSelector((state: any) => state.userProfile.profile);
  const userJwt = useSelector((state: any) => state.userProfile.userJwt);
  const [userCredentials, setUserCredentials] = useState<RegisteringUserCredentials>({
    username: '',
    email: '',
    password: '',
    confirmedPassword: ''
  });
  const dispatch = useDispatch();

  const insertData = async () => {
    const userRoles: Roles[] = [
      {
        id: '1',
        roleName: 'ROLE_USER'
      }
    ]
    const userPrincipalBody: UserPrincipal = {
      id: '',
      userId: '',
      userName: userCredentials.username,
      pwd: userCredentials.password,
      email: userCredentials.email,
      mobile: '',
      mobileRegion: '',
      location: country,
      birth: birthValue,
      gender: gender,
      userRoles: userRoles

    }

    await postUserPrincipal(userJwt, userPrincipalBody)
      .then(response => {
        //TODO - set is registered after successfully insertion
        // setIsRegistered('true');
        dispatch(setIsRegistered('true'));
        localStorage.setItem('isRegistered', 'true');
      })
      .catch(error => {
        console.error(error);
      });
  }

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    insertData();
  };
  const [birthValue, setBirthValue] = React.useState<Dayjs | null>(null);
  const [gender, setGender] = React.useState("");
  const handleGenderChange = (event: SelectChangeEvent) => {
    setGender(event.target.value as string);
  };
  const [country, setCountry] = React.useState("");
  const handleCountryChange = (event: SelectChangeEvent) => {
    setCountry(event.target.value as string);
  };
  const options = useMemo(() => countryList().getData(), []);
  const userNameChange = (e: any) => {
    console.log(e.target.value);
    setUserCredentials({
      ...userCredentials,
      username: e.target.value
    });
  }
  const userEmailChange = (e: any) => {
    console.log(e.target.value);
    setUserCredentials({
      ...userCredentials,
      email: e.target.value
    });
  }
  const onPasswordChange = (e: any) => {
    console.log(e.target.value);
    setUserCredentials({
      ...userCredentials,
      password: e.target.value
    });
  }
  const onConfirmPasswordChange = (e: any) => {
    console.log(e.target.value);
    setUserCredentials({
      ...userCredentials,
      confirmedPassword: e.target.value
    });
  }
  return (
    <Container component="main" maxWidth="xs">
      <form onSubmit={handleSubmit}>

        <InputLabel id="username">Username</InputLabel>
        <FormControl fullWidth>
          <Input onChange={userNameChange} />
        </FormControl>
        <InputLabel id="email">Email</InputLabel>
        <FormControl fullWidth>
          <Input type='email' onChange={userEmailChange} />
        </FormControl>
        <InputLabel id="password">Password</InputLabel>
        <FormControl fullWidth>
          <Input type='password' onChange={onPasswordChange} />
        </FormControl>
        <InputLabel id="confirmPassword">Password</InputLabel>
        <FormControl fullWidth>
          <Input type='password' onChange={onConfirmPasswordChange} />
        </FormControl>
        <InputLabel id="birthday">Birthday</InputLabel>
        <FormControl fullWidth>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker value={birthValue} onChange={(newValue) => setBirthValue(newValue)} />
          </LocalizationProvider>
        </FormControl>

        <InputLabel id="gender">Gender</InputLabel>
        <FormControl fullWidth>
          <Select
            labelId="gender"
            id="gender"
            value={gender}
            label="Gender"
            onChange={handleGenderChange}
          >
            <MenuItem value={'M'}>Male</MenuItem>
            <MenuItem value={'F'}>Female</MenuItem>
          </Select>
        </FormControl>

        <InputLabel id="country">Country</InputLabel>
        <FormControl fullWidth>
          <Select
            labelId="country"
            id="country"
            value={country}
            label="Country"
            onChange={handleCountryChange}
          >
            {options.map((i) => (
              <MenuItem key={i.value} value={i.label}>{i.label}</MenuItem>)
            )}
          </Select>
        </FormControl>

        <Button type="submit" color="primary" variant="contained" >Submit</Button>
        {/* <button type="submit">Submit</button> */}
      </form>
    </Container>


  );
};

export default RegistrationPage;