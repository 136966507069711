import { call, put, takeLatest } from 'redux-saga/effects';
import {
    checkLoginSession,
    checkLoginSessionFailed,
    checkLoginSessionSuccess,
    loginByCredentials,
    loginByCredentialsFail,
    loginByCredentialsSuccess
} from './userLogin.slice';
import { fetchLoginSession, loginWithCredentials } from './userLogin.service';
import { PayloadAction } from '@reduxjs/toolkit';
import { LoginResponse, LoginCredentials } from '../../domain/login.model';

function* login(action: PayloadAction<LoginCredentials>) {
    try {
        const response: LoginResponse = yield call(
            loginWithCredentials,
            action.payload.username,
            action.payload.password,
            action.payload.clientId
        );
        yield put(loginByCredentialsSuccess(response.accessToken));
        window.location.href = response.redirectUri;
    } catch (e) {
        yield put(
            loginByCredentialsFail((e as unknown as any).message as string)
        );
    }
}

function* checkSession(action: PayloadAction) {
    try {
        const response: LoginResponse = yield call(
            fetchLoginSession
        );
        yield put(checkLoginSessionSuccess(response.accessToken));
    } catch (e) {
        yield put(
            checkLoginSessionFailed()
        );
    }
}
/*
  Starts fetchUser on each dispatched `USER_FETCH_REQUESTED` action.
  Allows concurrent fetches of user.
*/
export function* userLoginSaga() {
    yield takeLatest(loginByCredentials.type, login);
    yield takeLatest(checkLoginSession.type, checkSession);
}
