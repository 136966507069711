import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { LoginCredentials } from '../../domain/login.model';

export type UserLoginState = {
    accessToken: string;
    error: any;
    loading: boolean;
}

const userLoginSlice = createSlice({
    name: 'userLogin',
    initialState: {
        accessToken: '',
        error: null,
        loading: false
    },
    reducers: {
        loginByCredentials: (state, action: PayloadAction<LoginCredentials>) => {
            state.error = null;
            state.loading = true;
        },
        loginByCredentialsSuccess: (state, action) => {
            state.accessToken = action.payload;
        },
        loginByCredentialsFail: (state, action) => {
            state.error = action.payload;
        },
        checkLoginSession: (state, action: PayloadAction) => {
            state.error = null;
            state.loading = true;
        },
        checkLoginSessionSuccess: (state, action: PayloadAction<string>) => {
            state.error = null;
            state.accessToken = action.payload;
        },
        checkLoginSessionFailed: (state, action: PayloadAction) => {
            state.error = null;
            state.loading = true;
            state.accessToken = '';
        },
    },
});

export const { loginByCredentials, loginByCredentialsSuccess, loginByCredentialsFail,
    checkLoginSession, checkLoginSessionSuccess, checkLoginSessionFailed } = userLoginSlice.actions;
const userLogInReducer = userLoginSlice.reducer;
export { userLogInReducer };