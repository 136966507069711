import axios from 'axios';
import { BASE_API_URL } from '../domain/constants/api';
async function getJwt(oauthTokenData:string,oauthProvider:string){
    
    var data = JSON.stringify({
        "tokenProvider": oauthProvider,
        "token": oauthTokenData
      });
      
      var config = {
        method: 'post',
        url: BASE_API_URL + '/external/jwt/token',
        headers: { 
          'Content-Type': 'application/json'
        },
        data : data
      };

    return axios(config)
}

async function validateGoogleIdToken(idToken:string){
    return axios({
        method: 'post',
        url: BASE_API_URL + '/external/google-mgt/access-token-validation',
        headers: {
            'Content-Type': 'text/plain',
        },
        data: idToken
        
    })
    
}

export { getJwt , validateGoogleIdToken };