import { combineReducers } from "redux";
import userProfileReducer from '../features/userProfile.slice';
import { userLogInReducer } from '../features/userLogin.slice';

export const rootReducer = combineReducers({
    userProfile: userProfileReducer,
    userLogin: userLogInReducer
});

export type RootState = ReturnType<typeof rootReducer>;
